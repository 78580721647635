<template>
  <div class="admin" v-if="isAdmin">
    <h1 class="admin-title">Admin</h1>
    <div class="forms-container" v-if="isAdmin">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">Create Work Order</b-button>
          </b-card-header>
          <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
            <b-card-body class="tab-container">
                  <b-form @submit.prevent="createService">
                    <h1>Add a New Work Order</h1>

                    <b-form-group id="input-group-1" label="Work Site" label-for="input-1">
                      <b-form-select
                        id="input-1"
                        v-model="serviceForm.workSite"
                        :options="sites"
                        required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-3" label="IVR Link" label-for="input-3">
                      <b-form-input
                        id="input-3"
                        v-model="serviceForm.IVRLink"
                        placeholder="Enter IVR Link"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-4" label="Account Number" label-for="input-4">
                      <b-form-input
                        id="input-4"
                        v-model="serviceForm.accountNumber"
                        placeholder="Enter Account Number"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      label="EMCOR Work Order Number"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="serviceForm.workOrderNumber"
                        placeholder="Enter 8 Digit Work Order Number"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <label for="requested-date">Requested Date</label>
                    <b-form-datepicker id="requested-date" v-model="serviceForm.requestedDate" class="mb-2" value-as-date></b-form-datepicker>

                    <label for="required-completion-date">Required Completion Date</label>
                    <b-form-datepicker id="required-completion-date" v-model="serviceForm.requiredCompletionDate" class="mb-2" value-as-date></b-form-datepicker>

                    <b-form-group id="input-group-5" label="Priority" label-for="input-5">
                      <b-form-select
                        id="input-5"
                        v-model="serviceForm.priority"
                        :options="priorities"
                        required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-6" label="Description of Work" label-for="input-6">
                      <b-form-input
                        id="input-6"
                        v-model="serviceForm.descriptionOfWork"
                        placeholder="Enter Description of Work"
                        required
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-7" label="Technician Responsible" label-for="input-7">
                      <b-form-select
                        id="input-7"
                        v-model="serviceForm.technicianResponsible"
                        :options="technicians"
                        required
                      ></b-form-select>
                    </b-form-group>

                    <b-button type="submit" variant="info">Submit</b-button>
                  </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="info">Create a User</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
        <b-card-body class="tab-container">
              <b-form @submit.prevent="register">
                <h1>Create a New User</h1>
            <b-form-group
              id="input-group-1"
              label="Email address:"
              label-for="input-1"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="input-1"
                v-model="userForm.email"
                type="email"
                placeholder="Enter email"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="First Name:" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="userForm.firstName"
                placeholder="Enter first name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Last Name:" label-for="input-3">
              <b-form-input
                id="input-3"
                v-model="userForm.lastName"
                placeholder="Enter last name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Role:" label-for="input-4">
              <b-form-select
                id="input-4"
                v-model="userForm.role"
                :options="roles"
                required
              ></b-form-select>
            </b-form-group>

            <b-button type="submit" variant="info">Submit</b-button>
          </b-form>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info">Create a Work Site</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body class="tab-container">
              <b-form @submit.prevent="createSite">
                <h1>Create a New Work Site</h1>

                <b-form-group id="input-group-2" label="Property Name" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="siteForm.propName"
                    placeholder="Enter Property name"
                    required
                  ></b-form-input>
                </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Property Number"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="siteForm.propNumber"
                placeholder="Enter Property Number"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="PWS Number" label-for="input-3">
              <b-form-input
                id="input-3"
                v-model="siteForm.pwsNum"
                placeholder="Enter PWS Number"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Water Source" label-for="input-4">
              <b-form-select
                id="input-4"
                v-model="siteForm.source"
                :options="waterSources"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-5" label="Property Type" label-for="input-5">
              <b-form-select
                id="input-5"
                v-model="siteForm.propType"
                :options="propTypes"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-6" label="Operating Period" label-for="input-6">
              <b-form-select
                id="input-6"
                v-model="siteForm.operatingPeriod"
                :options="operatingPeriods"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-7" label="TC Frequency" label-for="input-7">
              <b-form-select
                id="input-7"
                v-model="siteForm.tcFrequency"
                :options="tcFrequencies"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-8" label="Nitrate Frequency" label-for="input-8">
              <b-form-select
                id="input-8"
                v-model="siteForm.nitrateFrequency"
                :options="nitrateFrequencies"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-9" label="Street Address" label-for="input-9">
              <b-form-input
                id="input-9"
                v-model="siteForm.streetAddress"
                placeholder="Enter Street Address"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-10" label="City/State" label-for="input-10">
              <b-form-input
                id="input-10"
                v-model="siteForm.city"
                placeholder="Ex: Provo, UT"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-11" label="Treatment Process" label-for="input-11">
              <b-form-input
                id="input-11"
                v-model="siteForm.treatmentProcess"
                placeholder="Enter Treatment Process"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-12" label="Name of FM Manager" label-for="input-12">
              <b-form-input
                id="input-12"
                v-model="siteForm.fmManagerName"
                placeholder="Enter FM Manager Name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-13" label="FM Manager Phone Number" label-for="input-13">
              <b-form-input
                id="input-13"
                v-model="siteForm.fmManagerPhone"
                placeholder="Enter FM Manager Phone Number"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-14" label="Chlorine Jugs" label-for="input-14">
              <b-form-input
                id="input-14"
                v-model="siteForm.chlorineJugs"
                placeholder="Enter number of Chlorine Jugs"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-15" label="CL17 Reagents" label-for="input-15">
              <b-form-input
                id="input-15"
                v-model="siteForm.cl17Reagents"
                placeholder="Enter number of CL17 Reagents"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-16" label="Exterior Keybox Location" label-for="input-16">
              <b-form-input
                id="input-16"
                v-model="siteForm.extKeyBoxLocation"
                placeholder="Enter Exterior Keybox Location"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-17" label="Exterior Keybox Code" label-for="input-17">
              <b-form-input
                id="input-17"
                v-model="siteForm.extKeyBoxCode"
                placeholder="Enter Exterior Keybox Code"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-18" label="Interior Keybox Location" label-for="input-18">
              <b-form-input
                id="input-18"
                v-model="siteForm.intKeyBoxLocation"
                placeholder="Enter Interior Keybox Location"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-19" label="Interior Keybox Code" label-for="input-19">
              <b-form-input
                id="input-19"
                v-model="siteForm.intKeyBoxCode"
                placeholder="Enter Interior Keybox Code"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-20" label="Technician Responsible" label-for="input-20">
              <b-form-select
                id="input-20"
                v-model="siteForm.technicianResponsible"
                :options="technicians"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-21" label="Route" label-for="input-21">
              <b-form-select
                id="input-21"
                v-model="siteForm.route"
                :options="routes"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-22" label="Additional Notes" label-for="input-22">
              <b-form-input
                id="input-22"
                v-model="siteForm.notes"
                placeholder="Enter Additional Notes"
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="info">Submit</b-button>
          </b-form>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info">Create a Route</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body class="tab-container">
              <b-form @submit.prevent="createRoute">
                <h1>Create a New Route</h1>
            <b-form-group
              id="input-group-1"
              label="Route Name"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="routeForm.name"
                placeholder="Enter Route Name"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Drop Off Location" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="routeForm.dropLocation"
                placeholder="Enter Drop Off Location"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Time Drop Off Location Closes" label-for="input-3">
              <b-form-input
                id="input-3"
                v-model="routeForm.timeDropLocationCloses"
                placeholder="Enter Time Drop Off Location Closes"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Technician Responsible" label-for="input-4">
              <b-form-select
                id="input-4"
                v-model="routeForm.technicianResponsible"
                :options="technicians"
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-5" label="Additional Notes" label-for="input-5">
              <b-form-input
                id="input-5"
                v-model="routeForm.notes"
                placeholder="Enter Additional Notes"
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="info">Submit</b-button>
          </b-form>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>

  <p v-if="error" class="error">{{error}}</p>
  <b-modal id="successModal" title="Success!" ok-only>Email was successfully sent!</b-modal>
  <b-modal id="failureModal" title="Oops!" ok-only>Operation failed, try again later. <br>error: {{error}}</b-modal>
</div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Admin',
  data() {
    return {
      error: '',
      userForm: {
          email: '',
          firstName: '',
          lastName: '',
          role: null,
        },
      siteForm: {
        propNumber: '',
        propName: '',
        pwsNum: '',
        source: '',
        propType: '',
        operatingPeriod: '',
        tcFrequency: '',
        nitrateFrequency: '',
        streetAddress: '',
        city: '',
        treatmentProcess: '',
        fmManagerName: '',
        fmManagerPhone: '',
        chlorineJugs: 'NA',
        cl17Reagents: 'NA',
        technicianResponsible: null,
        extKeyBoxLocation: '',
        extKeyBoxCode: '',
        intKeyBoxLocation: '',
        intKeyBoxCode: '',
        route: null,
        notes: '',
      },
      routeForm: {
        name: '',
        dropLocation: '',
        timeDropLocationCloses: '',
        technicianResponsible: null,
        notes: '',
      },
      serviceForm: {
        workOrderNumber: '',
        priority: '',
        requestedDate: '',
        requiredCompletionDate: '',
        workSite: null,
        IVRLink: '',
        accountNumber: '',
        descriptionOfWork: '',
        technicianResponsible: null,
      },
      roles: [{ text: 'Select One', value: null }, "Technician", "Admin", "Client"],
      operatingPeriods: [{ text: 'Select One', value: '' }, "Year Round", "Seasonal"],
      tcFrequencies: [{ text: 'Select One', value: '' }, "Monthly", "Quarterly"],
      nitrateFrequencies: [{ text: 'Select One', value: '' }, "Annually", "Quarterly"],
      propTypes: [{ text: 'Select One', value: '' }, "MTGH", "RCMP"],
      waterSources: [{ text: 'Select One', value: '' }, "Well", "Spring", "Well and Spring"],
      priorities: [{ text: 'Select One', value: '' }, "P1", "P2", "P3", "PM"],
      routes: [{ text: 'Select One', value: null }, {text: "No Route", value: null}],
      technicians: [{ text: 'Select One', value: null }],
      sites: [{ text: 'Select One', value: null }],
    }
  },
  methods: {
    async register(event) {
      event.preventDefault()
      this.error = '';
      if (!this.userForm.firstName || !this.userForm.lastName || !this.userForm.email || !this.userForm.role)
        return;
      try {
        let response = await axios.post('/api/users/invite', {
          firstName: this.userForm.firstName,
          lastName: this.userForm.lastName,
          email: this.userForm.email,
          role: this.userForm.role,
        });

        if (response.status == 200) {
          this.$bvModal.show("successModal");
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$bvModal.show("failureModal");
      }
    },
    async createSite() {
      try {
        let response = await axios.post('/api/sites/', {
            propNumber: this.siteForm.propNumber,
            propName: this.siteForm.propName,
            pwsNum: this.siteForm.pwsNum,
            source: this.siteForm.source,
            propType: this.siteForm.propType,
            operatingPeriod: this.siteForm.operatingPeriod,
            tcFrequency: this.siteForm.tcFrequency,
            nitrateFrequency: this.siteForm.nitrateFrequency,
            streetAddress: this.siteForm.streetAddress,
            city: this.siteForm.city,
            treatmentProcess: this.siteForm.treatmentProcess,
            fmManagerName: this.siteForm.fmManagerName,
            fmManagerPhone: this.siteForm.fmManagerPhone,
            chlorineJugs: this.siteForm.chlorineJugs,
            cl17Reagents: this.siteForm.cl17Reagents,
            technicianResponsible: this.siteForm.technicianResponsible,
            extKeyBoxLocation: this.siteForm.extKeyBoxLocation,
            extKeyBoxCode: this.siteForm.extKeyBoxCode,
            intKeyBoxLocation: this.siteForm.intKeyBoxLocation,
            intKeyBoxCode: this.siteForm.intKeyBoxCode,
            route: this.siteForm.route,
            notes: this.siteForm.notes
        });

        if (response.status == 200) {
          this.siteForm.propNumber = '';
          this.siteForm.propName = '';
          this.siteForm.pwsNum = '';
          this.siteForm.source = '';
          this.siteForm.propType = '';
          this.siteForm.operatingPeriod = '';
          this.siteForm.tcFrequency = '';
          this.siteForm.nitrateFrequency = '';
          this.siteForm.streetAddress = '';
          this.siteForm.city = '';
          this.siteForm.treatmentProcess = '';
          this.siteForm.fmManagerName = '';
          this.siteForm.fmManagerPhone = '';
          this.siteForm.chlorineJugs = 0;
          this.siteForm.cl17Reagents = 0;
          this.siteForm.technicianResponsible = null;
          this.siteForm.extKeyBoxLocation = '';
          this.siteForm.extKeyBoxCode = '';
          this.siteForm.intKeyBoxLocation = '';
          this.siteForm.intKeyBoxCode = '';
          this.siteForm.route = null;
          this.siteForm.notes = '';
        }
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async createRoute() {
      this.error = '';
      if (!this.routeForm.name || !this.routeForm.dropLocation || !this.routeForm.timeDropLocationCloses ||
        !this.routeForm.technicianResponsible || !this.routeForm.notes)
        return;
      try {
        let response = await axios.post('/api/routes/', {
          name: this.routeForm.name,
          dropLocation: this.routeForm.dropLocation,
          timeDropLocationCloses: this.routeForm.timeDropLocationCloses,
          technicianResponsible: this.routeForm.technicianResponsible,
          notes: this.routeForm.notes,
        });

        if (response.status == 200) {
          this.routeForm.name = '';
          this.routeForm.dropLocation = '';
          this.routeForm.timeDropLocationCloses = '';
          this.routeForm.technicianResponsible = null;
          this.routeForm.notes = '';
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$bvModal.show("failureModal");
      }
    },
    async createService() {
      this.error = '';
      console.log("technicianResponsible");
      console.log(this.serviceForm.technicianResponsible);
      try {
        let response = await axios.post('api/services/', {
          workOrderNumber: this.serviceForm.workOrderNumber,
          priority: this.serviceForm.priority,
          requestedDate: this.serviceForm.requestedDate,
          requiredCompletionDate: this.serviceForm.requiredCompletionDate,
          workSite: this.serviceForm.workSite,
          technicianResponsible: this.serviceForm.technicianResponsible,
          IVRLink: this.serviceForm.IVRLink,
          accountNumber: this.serviceForm.accountNumber,
          descriptionOfWork: this.serviceForm.descriptionOfWork
        });

        if (response.status == 200) {
          this.serviceForm.workOrderNumber = '';
          this.serviceForm.priority = '';
          this.serviceForm.requestedDate = '';
          this.serviceForm.requiredCompletionDate = '';
          this.serviceForm.workSite = null;
          this.serviceForm.technicianResponsible = null;
          this.serviceForm.IVRLink = '';
          this.serviceForm.accountNumber = '';
          this.serviceForm.descriptionOfWork = '';
        }
      } catch(error) {
        this.error = error.response.data.message;
        this.$bvModal.show("failureModal");
      }
    },
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
      this.$router.push({ path: "/"});
    }

    try {
      let response = await axios.get('/api/routes');
      for(let i = 0; i < response.data.length; i++) {
        if (response.data[i].name) {
          this.routes.push({text: response.data[i].name, value: response.data[i]});
        }
        else {
          this.routes.push({text: "Route " + response.data[i].number, value: response.data[i]});
        }
      }

      response = await axios.get('/api/users/technicians');
      for(let i = 0; i < response.data.length; i++) {
        this.technicians.push({text: response.data[i].firstName, value: response.data[i]});
      }

      response = await axios.get('api/sites/');
      for(let i = 0; i < response.data.length; i++) {
        this.sites.push({text: response.data[i].propName + " - " + response.data[i].propNumber, value: response.data[i]});
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    isAdmin() {
      if (!this.$root.$data.user) {
        return false;
      }
      if (this.$root.$data.user.role == "Admin") {
        return true;
      }
      return false;
    }
  },
}
</script>

<style scoped>
.admin-title {
  padding-top: 10px;
}

.forms-container {
  display: flex;
  justify-content: center;
}

.space-above {
  margin-top: 50px;
}

h1 {
  font-size: 28px;
  font-variant: capitalize;
}

.accordion {
  width: 100%;
}

.collapse {
  padding-right: 0px;
}

.tab-container {
  padding-left: 20px;
  padding-right: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 300px;
}

.b-form-datepicker {
  width: 300px;
}

select {
  width: 300px;
}

.error {
  margin-top: 10px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}
</style>
