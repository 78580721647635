<template>
  <div class="sites">
    <b-card no-body class="container">
      <div v-for="route in routes" :key="route._id">
        <h3>{{route.number}}: {{route.name}}</h3>
        <div v-for="site in route.sites" :key="site._id" class="site-box">
          <router-link :to="{ name: 'Site', params: { id: site._id }}">
            <b-card class="site" bg-variant="primary" text-variant="white" style="max-width: 120rem; margin-bottom:5px;">
              <p>{{site.propName}}</p>
            </b-card>
          </router-link>
        </div>
      </div>
    </b-card>
    <b-modal id="failureModal" title="Oops!" ok-only>Operation failed, try again later. <br>error: {{error}}</b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Sites",
  data() {
    return {
      routes: [],
      error: "",
    }
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
      this.$router.push({ path: "/"});
    }

    try {
      let response = await axios.get('/api/routes');
      this.routes = response.data;
    } catch(error) {
      this.error = error.response.data.message;
      this.$bvModal.show("failureModal");
    }
  }
}
</script>

<style scoped>

.container {
  padding-bottom: 10px;
  padding-top: 10px;
}

.bg-primary {
    background-color: #213571 !important;
}

.site-box {
  margin-bottom: 10px;
}

.site {
  display: flex;
  justify-content: center;
  align-items: center;
}

.site p {
  margin: 10px;
}
</style>
