<template>
<div class="main">
  <b-card no-body class="container">
        <b-card title="Urgent" title-tag="h2" class="service-container" id="top-service-container">
          <div v-for="service in urgentServices" :key="service._id">
            <router-link :to="{ name: 'Service', params: { id: service._id }}">
              <b-card bg-variant="danger" text-variant="light" style="max-width: 120rem; margin-bottom:5px;">
                <b-card-text>{{service.workSite.propName}}</b-card-text>
                <b-card-text>{{service.descriptionOfWork}}</b-card-text>
                <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
              </b-card>
            </router-link>
          </div>
        </b-card>
        <b-card title="Approaching Due" title-tag="h2" class="service-container">
          <div v-for="service in approachingServices" :key="service._id">
            <router-link :to="{ name: 'Service', params: { id: service._id }}">
              <b-card bg-variant="warning" text-variant="dark" style="max-width: 120rem; margin-bottom:5px;">
                <b-card-text>{{service.workSite.propName}}</b-card-text>
                <b-card-text>{{service.descriptionOfWork}}</b-card-text>
                <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
              </b-card>
            </router-link>
          </div>
        </b-card>
        <b-card title="Upcoming Work Orders" title-tag="h2" class="service-container">
          <div v-for="route in pendingServices" :key="pendingServices.indexOf(route)">
            <h5 v-if="route[0].workSite.route.name">Route {{route[0].workSite.route.number}}: {{route[0].workSite.route.name}}</h5>
            <h5 v-else>Route {{route[0].workSite.route.number}}</h5>
            <div v-for="service in route" :key="service._id">
              <router-link :to="{ name: 'Service', params: { id: service._id }}">
                <b-card class="pending" bg-variant="primary" text-variant="light" style="max-width: 120rem;">
                  <b-card-text>{{service.workSite.propName}}</b-card-text>
                  <b-card-text>{{service.descriptionOfWork}}</b-card-text>
                  <b-card-text>Required Completion: {{service.requiredCompletionDateString}}</b-card-text>
                </b-card>
              </router-link>
            </div>
          </div>
        </b-card>
  </b-card>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomePage',
  data() {
    return {
      pendingServices: [],
      approachingServices: [],
      urgentServices: [],
    }
  },
  async created() {
    let response = await axios.get('/api/services/pending');
    this.pendingServices = response.data.pending;
    this.approachingServices = response.data.approaching;
    this.urgentServices = response.data.urgent;
  },
  methods: {

  },
  computed: {
    user() {
      return this.$root.$data.user;
    }
  }
}
</script>

<style scoped>
.active-tab {
  background-color: #17a2b8 !important;
}

.nav-link.active {
  background-color: #17a2b8 !important;
}

.card-body p {
  margin-bottom: 0px !important;
}

.tab-content > .active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: white;
  background-color: #17a2b8 !important;
}

#top-service-container {
  margin-top: 20px;
}

.service-container {
  margin-bottom: 20px;
  width: 100%;
}

.bg-gray {
  background-color: lightgray;
}

.pending {
  margin-bottom: 5px;
}

.bg-primary {
    background-color: #213571 !important;
}
</style>
