<template>
  <div id="app">
<b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand class="md-mx-auto" id="branding" to="/">
        <img src="@/assets/rogueLogo-removebg-preview.png" id="logo" class="d-inline-block align-top" alt="Rogue">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" to="/sites">Sites</b-nav-item>
          <b-nav-item href="#" to="/admin" v-if="isAdmin">Admin</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#" @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    methods: {
      async logout() {
        try {
          await axios.delete("/api/users");
          this.$root.$data.user = null;
          this.$router.push({ path: "/"});
        } catch (error) {
          this.$root.$data.user = null;
        }
      },
    },
    computed: {
      isLoggedIn() {
        if (this.$root.$data.user) {
          return true;
        }
        return false;
      },
      isAdmin() {
        if (!this.$root.$data.user) {
          return false;
        }
        else if (this.$root.$data.user.role == "Admin") {
          return true;
        }
        return false;
      }
    },
  }
</script>

<style scoped>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: white !important;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#logo {
  height: 50px !important;
  width: 50px !important;
}

#branding {
  padding-left: 0;
  height: 50px !important;
  width: 50px !important;
  padding-bottom: 5px;
  display: flex;
}

#nav-collapse {
  padding-right: 0;
}

.nav-link {
  color: white !important;
}

.navbar-dark {
  color: white !important;
}

em {
  color: white !important;
}
</style>
