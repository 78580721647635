<template>
  <div class="hero">
    <div class="heroBox">
      <b-form @submit.prevent="login">
        <h1>Login</h1>
    <b-form-group
      id="input-group-1"
      label="Username:"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="form.username"
        type="text"
        placeholder="Enter Username"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" label="Password:" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="form.password"
        type="password"
        placeholder="Enter Password"
        required
      ></b-form-input>
    </b-form-group>

    <b-button type="submit" variant="primary">Submit</b-button>
  </b-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        password: '',
        username: '',
      },
      errorLogin: '',
    }
  },
  methods: {
    async login() {
      this.error = '';
      this.errorLogin = '';
      if (!this.form.password || !this.form.username)
        return;
      try {
        let response = await axios.post('/api/users/login', {
        username: this.form.username,
        password: this.form.password,
      });
      this.$root.$data.user = response.data.user;
      } catch (error) {
        this.errorLogin = "Error: " + error.response.data.message;
        this.$root.$data.user = null;
      }
    },
  },
}
</script>

<style scoped>
h1 {
  font-size: 28px;
  font-variant: capitalize;
}
.hero {
  padding: 60px;
  display: flex;
  justify-content: center;
}

.heroBox {
  text-align: center;
}

.hero form {
  font-size: 14px;
}

.hero form legend {
  font-size: 20px;
}
input {
  margin-right: 10px;
}
.error {
  margin-top: 20px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}
</style>
